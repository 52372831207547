<template>
  <div>
    <!-- <div class="bg">
      <img src="../assets/bg.png" class="bg-image" alt="" />
    </div> -->
    <div :style="cssVars" class="bg-image">
      <div class="title"><h1>欢迎来到搜图大师-www.soutudashi.com</h1></div>
    </div>
    <div class="bz_header">
      <ul class="pict_category">
        <a
          href="#"
          class="menu_item"
          @click.prevent="click_pict_category($event, index)"
          v-for="(item, index) in pic_type.main"
          :key="index"
          :class="{
            model_choice: selectedIndex === index,
            noactive_choice: selectedIndex != index,
          }"
        >
          <li :name="item">
            {{ item }}
          </li>
        </a>
      </ul>
      <ul class="menu_type">
        <a
          href="#"
          class="menu_item"
          @click.prevent="click_menu_type($event, ind)"
          v-for="(item, ind) in pic_style.main"
          :key="ind"
          :class="{
            model_choice: selectedIndex2 === ind,
            noactive_choice: selectedIndex2 != ind,
          }"
        >
          <li :name="item.type">{{ item.type }} ({{ item.num }})</li>
        </a>
      </ul>
    </div>
    <div class="bz_content">
      <waterfall
        class="pic_detail"
        :line-gap="item_width - 20"
        :single-max-width="800"
        :max-line-gap="item_width"
        :watch="pic_detail"
      >
        <!-- each component is wrapped by a waterfall slot -->
        <waterfall-slot
          v-for="(pic_item, index) in pic_detail"
          :width="pic_item.width"
          :height="pic_item.height"
          :order="index"
          :key="index"
          style="padding: 0px 0px 10px 10px; box-sizing: border-box"
        >
          <div class="content">
            <a :href="pic_item.link">
              <img v-lazy="pic_item.pic_url" :key="index" />
              <!-- <img v-lazy="pic_item.pic_url" /> -->
            </a>
          </div>
        </waterfall-slot>
      </waterfall>
      <!-- <ul class="pic_detail">
        <li v-for="pic_item in pic_detail">
          <div class="content">
            <a :href="pic_item.link"><img :src="pic_item.pic_url" alt="" /></a>
          </div>
        </li>
      </ul> -->
    </div>
    <div class="bz_page_part">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_no"
        :page-size="page_size"
        layout="prev, pager, next"
        :total="total"
        background
      >
      </el-pagination>
    </div>
    <!-- <button @click="down_load">点击下载图片</button> -->
  </div>
</template>

<script type="text/babel">
import { Waterfall, WaterfallSlot } from "vue-waterfall";
// import Waterfall from "vue-waterfall/lib/waterfall";
// import WaterfallSlot from "vue-waterfall/lib/waterfall-slot";
// import Vue from "vue";
// import VueLazyload from "vue-lazyload";

import { Loading } from "element-ui";
import CONSTANT from "@/config/constant";
// Vue.use(VueLazyload);
export default {
  data() {
    return {
      bg_image: CONSTANT.BG_2,
      pic_type: [],
      pic_style: [],
      page_size: 30,
      page_no: 1,
      pic_detail: [],
      tableData: [],
      total: 100,
      type: "全部",
      selectedIndex: 0,
      pic_category: "壁纸",
      selectedIndex2: 0,
      item_width: 200,
      loadingInstance: null,
    };
  },
  methods: {
    async get_category_stat(pic_type) {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_bz_category",
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: { pic_type: pic_type },
          }
        );
        // console.log("response类型顶顶顶顶: ", response, this.pic_category);
        return response.data;
      } catch (e) {
        console.log("失败拉~~~~~~~~~~~~~~");
      }
    },

    change_onload(event, item) {
      // console.log("change_onload执行了", event, item);
      item.is_onload = true;
    },

    handleImageError() {
      // console.log("图片加载失败~~~~~~~~~~~~~");
    },

    async get_pict_category(pic_type) {
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_pict_categorys"
        );
        // console.log("response: ", response);
        return response.data;
      } catch (e) {}
    },

    click_menu_type(event, index) {
      const bz_type = event.target.getAttribute("name");
      this.selectMenuItemIndex(index);
      // 如果点击了不一样的类型，页码就重置为1
      if (this.type != bz_type) {
        this.page_no = 1;
        this.type = bz_type;
        this.handleCurrentChange(this.page_no);
      } else {
        // 如果是同一个类型，就按照页码传入的查询
        this.get_bz_by_type(
          bz_type,
          this.pic_category,
          this.page_no,
          this.page_size
        );
      }
    },

    async click_pict_category(event, index) {
      // console.log("点击商家图片分类: ", event.target);
      let pic_category = event.target.getAttribute("name");

      // console.log(
      //   "点击了图片分类！！！！！！！！： ",
      //   pic_category,
      //   this.pic_category,
      //   this.type,
      //   this.pic_style
      // );
      this.pic_style = await this.get_category_stat(pic_category);

      this.selectItem(index);
      this.selectMenuItemIndex(0);

      // console.log("indexxxxxxx: ", this.selectedIndex, this.selectedIndex2);
      // console.log("点击了图片分类： ", bz_type);
      // 如果点击了不一样的类型，页码就重置为1
      if (this.pic_category != pic_category) {
        this.page_no = 1;
        this.pic_category = pic_category;
        this.type = "全部";
        this.handleCurrentChange(this.page_no);
      } else {
        // 如果是同一个类型，就按照页码传入的查询
        this.get_bz_by_type(
          this.bz_type,
          this.pic_category,
          this.page_no,
          this.page_size
        );
      }
    },

    async get_bz_by_type(bz_type, pic_type, page_no, page_size) {
      // console.log("传入参数: ", bz_type, pic_type, page_no, page_size);
      // 开启loading加载效果
      this.loadingInstance = Loading.service({ fullscreen: true });
      // console.log("开启loading加载效果");
      try {
        var response = await this.$http.post(
          "http://www.soutudashi.com/get_bz_by_type",

          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            data: { bz_type: bz_type, pic_type: pic_type },
            page_no: page_no,
            page_size: page_size,
          }
        );
        // console.log("response: ", response);
        this.total = response.data.total;
        this.pic_detail = response.data.main;
        // window.scrollTo(0, 100);
        return response.data.main;
      } catch (e) {
      } finally {
        // 清除loading加载效果
        this.loadingInstance.close();
        // console.log("关闭loading加载效果");
        window.scrollTo(0, 10);
      }
    },

    handleSizeChange(val) {
      this.page_size = val; // 改变每页显示的数量
      this.page_no = 1; // 重置当前页码
    },

    handleCurrentChange(val) {
      this.page_no = val; // 改变当前页码
      // console.log(
      //   "当前页面： ",
      //   this.type,
      //   this.pic_category,
      //   this.page_no,
      //   this.page_size
      // );
      this.get_bz_by_type(
        this.type,
        this.pic_category,
        this.page_no,
        this.page_size
      );
      // this.get_feedback_list(this.page_size, this.page_no); // 获取数据},
    },
    selectItem(index) {
      this.selectedIndex = index;
    },

    selectMenuItemIndex(index) {
      this.selectedIndex2 = index;
    },
  },
  components: {
    Waterfall,
    WaterfallSlot,
  },
  async mounted() {
    this.pic_type = await this.get_pict_category();
    this.pic_style = await this.get_category_stat(this.pic_category);
    // console.log(
    //   "mounted: ",
    //   "全部",
    //   this.pic_category,
    //   this.page_no,
    //   this.page_size
    // );
    this.pic_detail = await this.get_bz_by_type(
      "全部",
      this.pic_category,
      this.page_no,
      this.page_size
    );

    // console.log("pic_detail: ", this.pic_detail);
    // console.log(
    //   "元素的宽度： ",
    //   document.documentElement.offsetWidth,
    //   document.documentElement.offsetWidth / 5
    // );
    this.item_width = Math.floor(document.documentElement.offsetWidth / 6);
  },
  computed: {
    cssVars() {
      return {
        backgroundImage: `url(${this.bg_image})`,
        backgroundSize: "100% 200px",
        // '--container-height': this.containerHeight
      };
    },
  },
  // watch: {
  //   pic_detail: {
  //     handler(newVal, oldVal) {
  //       console.log("监控了~~~", newVal, oldVal);
  //     },
  //     deep: true,
  //   },
  // },
};
</script>

<style scoped>
.pict_category {
  margin-bottom: 10px;
}
.menu_type,
.pict_category {
  display: flex;
  list-style-type: none;
  margin-left: 15px;
  flex-wrap: wrap;
}

.menu_type > .menu_item {
  text-decoration: none;
  width: 200px;
  height: 70px;
  /* background-color: black; */
  color: white;
  font-size: 27px;
  align-items: center;
  line-height: 70px;
}
.pict_category > .menu_item {
  text-decoration: none;
  width: 200px;
  height: 70px;
  /* background-color: black; */
  color: white;
  font-size: 27px;
  align-items: center;
  line-height: 70px;
}

.menu_type > .menu_item:hover {
  /* transition: scale(1.1); */
  /* background-color: rgb(0, 0, 0, 0.5); */
}

.pict_category > .menu_item:hover {
  /* background-color: rgb(255, 255, 255); */
  /* color: black; */
  /* transform: scale(1.1); */
}

.menu_item > li:hover {
  /* transform: scale(1.1); */
  color: blue;
}
.bz_header,
.bz_content {
  padding: 0px 30px;
}

.pic_detail {
  margin-top: 20px;
  /* float: left; */
  /* display: flex; */
  /* flex-direction: column-reverse; */
  /* justify-content: right; */
  /* flex-wrap: wrap; */
  /* align-items: top; */
  /* text-decoration: none;
  list-style-type: none; */
  /* height: 3000px; */
  /* column-count: 5; */
  /* break-inside: initial; */
  /* column-width: 25%; */
  /* column-gap: 10px; */
  /* column-width: 20%; */
}

.content {
  width: 100%;
  height: 100%;
}
/* .pic_detail > aterfall-slot { */
/* display: block; */
/* margin: 15px; */
/* padding: 10px 0px; */
/* width: calc(20% - 20px); */
/* height: auto; */
/* } */
img {
  width: 100%;
  height: 100%;
  /* height: 350px; */
  border-radius: 20px;
}

.model_choice {
  background-color: rgba(0, 0, 0, 0.5);
}

.noactive_choice {
  background-color: rgb(0, 0, 0);
}
/* .bz_page_part {
  position: fixed;
  bottom: 0;
} */

.bg-image > .title {
  height: 200px;
  line-height: 200px;
}
/* .bg-image {
  width: 100%;
  height: 200px;
  object-fit: fill;
  padding: 0px 40px;
  margin: 10px auto;
} */
</style>
